import { Fragment, useEffect, useState } from 'react'
import BaseService from '../../helpers/baseServices'
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline'
// import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import { formatHugeAmount } from '../../utils/funtions';
import { RouteConstants } from '../../helpers/RouteConstants'
import boxImg from '../../images/empty box.png'
import ChooseDrawer from './chooseDrawer'
import Button from '../../components/Button'
import TransferCouponModal from './transferCouponModal'
import LoadingCoupons from './LoadingCoupons'
import AvailableCouponsCard from '../../components/AvailableCouponsCard'
import CouponCard from './CouponCard'
import CallToAction from '../../components/CallToAction'

const CouponsScreen = () => {
  const [isLoading, setIsLoading] = useState(false)
  // const [couponStats, setCouponStats] = useState<any>({})
  const [couponsData, setCouponsData] = useState<any>([])
  const [openBottom, setOpenBottom] = useState(false)
  const navigate = useNavigate()
  const [limit] = useState(5)
  const [total, setTotal] = useState(0)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [couponId, setCouponId] = useState('')
  const [cta, setCTA] = useState(true)

  const handleOpenTransfer = (id: string) => {
    handleOpenModal()
    setCouponId(id)
  }

  const handleCloseTransfer = () => setOpenTransfer(false)

  const handleOpenModal = () => {
    setOpenTransfer(true)
  }

  const openDrawerBottom = () => {
    setOpenBottom(!openBottom)
  }

  //fetch paid coupons
  const fetchPaidCoupons = async (lim: number) => {
    setIsLoading(true)
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.MY_AVAILABLE_PAID_COUPONS}?sorting=created_at:desc&limit=${lim}`
      )
      // console.log(response)
      setCouponsData(response?.data?.payload?.items)
      setTotal(response?.data?.payload?.total)

      setIsLoading(false)
    } catch (error) {
      // console.log(error)
    }
  }

  //handle on coupon select
  const onCouponSelect = (selected: any) => {
    navigate(`/coupons/${selected}`)
  }

  // const onLoadMore = () => {
  //   if (limit < total) {
  //     setLimit(limit + 5)
  //   }
  // }

  useEffect(() => {
    fetchPaidCoupons(limit)
  }, [limit])

  return (
    <Fragment>
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => openDrawerBottom()}
        />
      )}
      <div>
        {isLoading ? (
          <>
            {['', '', '', '', ''].map((dd, i) => (
              <LoadingCoupons key={i} />
            ))}
          </>
        ) : (
          <>
            {couponsData.length === 0 ? (
              <div className='flex flex-col justify-center items-center h-full dark:bg-dark-100 dark:text-white'>
                <img className='mt-10 lg:w-[300px]' src={boxImg} alt='empty' />
                <div className='text-center'>
                  You have no coupons yet.{' '}
                  <button
                    onClick={() => openDrawerBottom()}
                    className='text-[#5C8BFF] font-bold'
                  >
                    Click here
                  </button>{' '}
                  to buy coupon.
                </div>
              </div>
            ) : (
              <div className='mb-3 p-3 dark:bg-dark-100 dark:text-white'>
                <div className='pb-[150px]'>
                  {cta && <CallToAction onClose={() => setCTA(false)} />}
                  <AvailableCouponsCard />
                  {couponsData.map((coupon: any, i: number) => (
                    <CouponCard
                      key={i}
                      coupon={coupon}
                      handleOpenTransfer={handleOpenTransfer}
                      onCouponSelect={() => onCouponSelect(coupon.id)}
                    />
                  ))}

                  {limit >= total ? (
                    <></>
                  ) : (
                    <div className='pb-14 flex justify-center items-center'>
                      <Button
                        onClick={() => navigate('/all-coupons?q=available')}
                        // disabled={limit >= total}
                      >
                        <div className='flex items-center gap-2'>
                          View More
                          <ChevronDoubleDownIcon className='h-4 w-4' />
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {openTransfer ? (
        <TransferCouponModal
          openModal={openTransfer}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseTransfer}
          couponId={couponId}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default CouponsScreen
