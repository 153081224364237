import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
// import PhoneInput, { Value } from 'react-phone-number-input'
import Button from '../../components/Button'
import { Spinner } from '@material-tailwind/react'
import { RouteConstants } from '../../helpers/RouteConstants'
import ShowToast from '../../components/alerts/all_toasts'
import PinInput from 'react-pin-input'
import BaseService from '../../helpers/baseServices'
import { PhoneInput } from 'react-international-phone'
// import { Input } from 'antd'

type Props = {}

const ForgotPin = (props: Props) => {
  const [step, setStep] = useState<number>(1)
  const [isError, setIsError] = useState(false)
  const [phone, setPhone] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const navigate = useNavigate()
  const [code, setCode] = useState<number | any>()
  const [pin, setPin] = useState('')
  const [confirmPin, setConfirmPin] = useState('')
  const [wrongPin, setWrongPins] = useState(false)

  const submitResetPinRequest = async () => {
    try {
      setIsBusy(true)
      if (!phone) {
        setIsError(true)
      } else {
        //fire up reset code
        await BaseService.post_api_auth(RouteConstants.REQUEST_RESET_PIN_OTP, {
          phone
        })
        setStep(2)
        ShowToast.success_toast('Verification code sent')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsBusy(false)
    }
  }

  //handleOnPinComplete
  const handleOnPinComplete = () => {
    // console.log(value)
    setIsBusy(true)
    if (code) {
      setTimeout(() => {
        setStep(3)
        setIsBusy(false)
      }, 1500)
    } else {
      ShowToast.error_toast('Verification required')
      setIsBusy(false)
    }
  }

  //handleResend
  const handleResend = () => {
    setIsBusy(true)
    BaseService.post_api_auth(`${RouteConstants.REQUEST_RESET_PIN_OTP}`, {
      phone
    })
      .then((response) => {
        // console.log(response?.data);
        if (response?.status === 200 || response?.status === 201) {
          ShowToast.success_toast('Verification code sent')
        }
        setIsBusy(false)
      })
      .catch((error) => {
        // console.error(error);
        //handleCloseDrawer()
        ShowToast.error_toast(error?.response?.data?.message)
        setIsBusy(false)
      })
  }

  const handlePinReset = async () => {
    try {
      setIsBusy(true)
      if (pin !== confirmPin) {
        setWrongPins(true)
      } else {
        await BaseService.post_api_auth(RouteConstants.RESET_PIN, {
          pin,
          code,
          phone
        })
        ShowToast.success_toast('Pin reset successful')
        navigate('/')
      }
    } catch (e: any) {
      ShowToast.error_toast(e?.response?.data?.message)
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <Fragment>
      <div className='h-screen bg-neutral-50 overflow-hidden flex flex-col'>
        <div className='w-full px-[20px] mt-[20%]'>
          {step === 1 && (
            <div>
              <button onClick={() => navigate('/')}>
                <ChevronLeftIcon className='w-6 h-6' />
              </button>
              <div className='header-text-3 mt-8'>Forgot pin</div>
              <div className='body-text-2 pt-1'>
                Enter your phone number linked to your account.
              </div>
              <div className='flex flex-col mt-8 relative'>
                <small className={`${isError ? 'text-red-500' : ''} mb-2 ml-1`}>
                  Your mobile number
                </small>
                {/* <PhoneInput
                  className='input'
                  defaultCountry='GH'
                  inputStyle={{ width: '100%' }}
                  placeholder='Enter your mobile number'
                  value={phone}
                  onChange={(value: any) => setPhone(value)}
                  numberInputProps={{
                    className: 'focus:outline-none'
                  }}
                /> */}
                <PhoneInput
                  inputClassName='input'
                  defaultCountry='gh'
                  hideDropdown
                  placeholder='Enter your phone number'
                  inputStyle={{
                    width: '100%',
                    height: '55px',
                    fontSize: '18px',
                    border: '1px solid #939291',
                    padding: '8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px'
                  }}
                  value={phone}
                  onChange={(value: any) => setPhone(value)}
                  disabled={isBusy}
                />
                {/* <Input
                  className='py-4 border border-gray-500 focus-within:border-gray-500 focus-within:shadow-none'
                  maxLength={10}
                  size='large'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={isBusy}
                  placeholder='Enter Your Mobile Number'
                  prefix={
                    <div className='flex gap-1 items-center'>
                      <img src='/GH.png' alt='GH' />
                      <span>+233</span>
                    </div>
                  }
                />*/}
              </div>
              <div className='mt-[50px] flex justify-center items-center'>
                <Button
                  width='w-full flex justify-center'
                  onClick={() => {
                    submitResetPinRequest()
                  }}
                >
                  {isBusy ? <Spinner color='red' /> : <span>Continue</span>}
                </Button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <button
                onClick={() => {
                  setStep(1)
                }}
              >
                <ChevronLeftIcon className='w-6 h-6' />
              </button>
              <div className='w-full mt-5'>
                <div className='header-text-3'>Verify number</div>
                <div className='body-text-2 pt-1'>
                  Enter the 6-digit code sent to {phone}
                </div>
              </div>

              <div className='mt-[4rem]'>
                <PinInput
                  length={6}
                  initialValue=''
                  secret
                  secretDelay={100}
                  type='custom'
                  inputMode='number'
                  inputStyle={{
                    borderColor: '#939291',
                    backgroundColor: '#fff',
                    borderRadius: '8px'
                  }}
                  inputFocusStyle={{ borderColor: '#FF6200' }}
                  onComplete={(value, index) => {
                    setCode(value)
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className='mt-5'>
                {!isBusy && (
                  <div>
                    <p className='flex items-center gap-2'>
                      Didn’t receive code?{' '}
                      <span
                        className='btn-subtle'
                        onClick={() => handleResend()}
                      >
                        Resend.
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className='mt-[50px] flex justify-center items-center'>
                <Button
                  className='h-[55px] bg-primary text-white rounded-[8px] w-full flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400'
                  disabled={isBusy}
                  onClick={() => {
                    handleOnPinComplete()
                  }}
                >
                  {isBusy ? <Spinner /> : <span>Continue</span>}
                </Button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <button
                onClick={() => {
                  setStep(2)
                }}
              >
                <ChevronLeftIcon className='w-6 h-6' />
              </button>
              <div className='w-full mt-5'>
                <div className='header-text-3'>Reset Pin</div>
                <div className='body-text-2 pt-1'>
                  Create a new pin to secure your account.
                </div>
              </div>

              <div className='flex flex-col mt-8 relative'>
                <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
                  Your new pin
                </small>
                <input
                  className='input'
                  type='password'
                  value={pin}
                  maxLength={4}
                  onChange={(e) => setPin(e.target.value)}
                  placeholder='Enter your new pin'
                />
                {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
              </div>

              <div className='flex flex-col mt-8 relative'>
                <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
                  Confirm your pin
                </small>
                <input
                  className='input'
                  type='password'
                  value={confirmPin}
                  maxLength={4}
                  onChange={(e) => setConfirmPin(e.target.value)}
                  placeholder='Confirm your new pin'
                />
                {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
              </div>
              {wrongPin && (
                <div className='text-red-500 mt-2'>Pin mismatch!</div>
              )}

              <div className='mt-[100px] flex justify-center items-center'>
                <Button
                  className='h-[55px] bg-primary text-white rounded-[8px] w-full flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400'
                  disabled={isBusy}
                  onClick={() => {
                    handlePinReset()
                  }}
                >
                  {isBusy ? <Spinner /> : <span>Reset pin</span>}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ForgotPin
