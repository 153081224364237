import { Fragment, useState, useEffect } from 'react'
//import CustomNavigation from '../../components/CustomNavigation'
import {
  ChevronDownIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { RouteConstants } from '../../helpers/RouteConstants'
import BaseService from '../../helpers/baseServices'
import { Input } from 'antd'
import { debounce } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'

const BranchesScreen = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [allBranches, setAllBranches] = useState([])
  const [limit, setLimit] = useState(12)
  const [total, setTotal] = useState(0)

  const [searchInput, setSearchInput] = useState(false)

  const title = localStorage.getItem('rnm')

  const { id } = useParams()
  const navigate = useNavigate()

  const handleSearch = debounce(async (value) => {
    const filter = [
      {
        f: 'name',
        o: 'contains',
        p: [value],
        c: 'OR'
      },
      {
        f: 'code',
        o: 'contains',
        p: [value],
        c: 'OR'
      },
      {
        f: 'location',
        o: 'contains',
        p: [value]
      }
    ]

    setIsFetching(true)
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.ALL_REGIONS}/${id}/supported-branches?limit=${limit}&filters=${JSON.stringify(filter)}`
      )
      // console.log(response?.data)
      setAllBranches(response?.data?.payload?.items)
      setTotal(response?.data?.payload?.total)
      setIsFetching(false)
    } catch (error) {
      console.log(error)
    }
  }, 3000)

  //getAllFuelBranches
  const getAllFuelBranches = async (lim: number) => {
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.ALL_REGIONS}/${id}/supported-branches?limit=${lim}`
      )
      // console.log(response?.data)
      setAllBranches(response?.data?.payload?.items)
      setTotal(response?.data?.payload?.total)
      setIsFetching(false)
    } catch (error) {
      console.log(error)
    }
  }

  //onload
  useEffect(() => {
    getAllFuelBranches(limit)

    // eslint-disable-next-line
  }, [limit])

  //loadNext
  const loadNext = () => {
    setLimit(limit + 12)
  }

  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50 relative dark:bg-dark-100'>
        <div className='pb-[120px] h-screen overflow-hidden'>
          <div className='flex justify-between items-center pt-8 px-5 pb-2 shadow-md'>
            <button onClick={() => navigate(-1)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
              >
                <path
                  d='M12.5 16.6004L7.0667 11.1671C6.42503 10.5254 6.42503 9.47539 7.0667 8.83372L12.5 3.40039'
                  stroke='#484442'
                  stroke-width='1.5'
                  stroke-miterlimit='10'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </button>
            {searchInput ? (
              <Input
                className='ml-3 h-[44px] rounded-lg border border-neutral-200'
                suffix={
                  <MagnifyingGlassIcon className='w-5 h-5 text-neutral-200' />
                }
                //onPressEnter={(e) => handleSearch(e.target.value)}
                onChange={(e) => {
                  setIsFetching(true)
                  handleSearch(e.target.value)
                }}
              />
            ) : (
              <>
                <div className='font-bold text-lg text-neutral-300'>
                  {title}
                </div>
                <button onClick={() => setSearchInput(true)}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z'
                      fill='#484442'
                    />
                    <path
                      d='M21.9999 22.7504C21.8099 22.7504 21.6199 22.6804 21.4699 22.5304L19.4699 20.5304C19.1799 20.2404 19.1799 19.7604 19.4699 19.4704C19.7599 19.1804 20.2399 19.1804 20.5299 19.4704L22.5299 21.4704C22.8199 21.7604 22.8199 22.2404 22.5299 22.5304C22.3799 22.6804 22.1899 22.7504 21.9999 22.7504Z'
                      fill='#484442'
                    />
                  </svg>
                </button>
              </>
            )}
          </div>
          <div className='h-full overflow-y-auto'>
            <div className='h-full w-full'>
              {isFetching
                ? ['', '', '', '', '', '', '', '', '', '', '', ''].map(
                    (dd, i) => (
                      <div
                        key={i}
                        className='cursor-pointer p-5 border-b-[1px] border-white animate-pulse flex gap-3 items-center'
                      >
                        <div className='w-5 h-5 text-transparent bg-gray-300'></div>
                        <p className='w-[200px] bg-gray-300 h-2 rounded-md text-transparent'></p>
                      </div>
                    )
                  )
                : allBranches.map((branch: any) => (
                    <div
                      key={branch?.id}
                      className='cursor-pointer p-5 border-b-[1px] border-white flex gap-3 items-center'
                    >
                      <div className='rounded-[12px] p-[8px] flex justify-center items-center bg-primary'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='28'
                          height='32'
                          viewBox='0 0 28 32'
                          fill='none'
                        >
                          <path
                            d='M26.7612 7.90279L26.7782 7.88582L21.3648 2.47249C20.8727 1.98037 20.0582 1.98037 19.5661 2.47249C19.0739 2.96461 19.0739 3.77915 19.5661 4.27128L22.2473 6.95249C20.4655 7.63128 19.2606 9.44703 19.5661 11.5513C19.8376 13.4179 21.4327 14.9282 23.2994 15.1319C24.097 15.2167 24.7927 15.081 25.4545 14.7925V27.0276C25.4545 27.961 24.6909 28.7246 23.7576 28.7246C22.8242 28.7246 22.0606 27.961 22.0606 27.0276V19.3913C22.0606 17.5246 20.5333 15.9973 18.6667 15.9973H16.9697V4.11855C16.9697 2.25188 15.4424 0.724609 13.5758 0.724609H3.39394C1.52727 0.724609 0 2.25188 0 4.11855V29.5731C0 30.5064 0.763636 31.2701 1.69697 31.2701H15.2727C16.2061 31.2701 16.9697 30.5064 16.9697 29.5731V18.5428H19.5152V26.7901C19.5152 29.0131 21.1103 31.0325 23.3164 31.2531C25.8618 31.5076 28 29.5222 28 27.0276V10.9064C28 9.73552 27.5249 8.66643 26.7612 7.90279ZM13.5758 12.6034H3.39394V5.81552C3.39394 4.88218 4.15758 4.11855 5.09091 4.11855H11.8788C12.8121 4.11855 13.5758 4.88218 13.5758 5.81552V12.6034ZM23.7576 12.6034C22.8242 12.6034 22.0606 11.8398 22.0606 10.9064C22.0606 9.97309 22.8242 9.20946 23.7576 9.20946C24.6909 9.20946 25.4545 9.97309 25.4545 10.9064C25.4545 11.8398 24.6909 12.6034 23.7576 12.6034Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <div>
                        <p className='text-header-4'>{branch?.name}</p>
                        <p className='text-[#484442] text-sm'>
                          {branch?.location}
                        </p>
                      </div>
                    </div>
                  ))}

              {isFetching ? (
                <></>
              ) : (
                <>
                  {limit >= total ? (
                    <></>
                  ) : (
                    <div className='mt-5 pb-10 flex justify-end'>
                      <button
                        className='py-1 px-3 w-fit rounded-[5px] text-white bg-orange-800 flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed'
                        onClick={() => loadNext()}
                        disabled={limit >= total}
                      >
                        More
                        <ChevronDownIcon className='w-4 h-4 text-white' />
                      </button>
                    </div>
                  )}
                </>
              )}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        {/* {
                    limit >= total ?
                        <></>
                        :
                        <div className='mb-20 flex justify-center items-center absolute bottom-[-20px] left-0 right-0'>
                            <button className='px-3 py-1 rounded-[5px] bg-orange-800 text-white flex justify-center items-center gap-2 disabled:bg-gray-400 disabled:cursor-not-allowed' onClick={() => onLoadMore()} disabled={limit >= total}>
                                View More
                                <ChevronDoubleDownIcon className='h-4 w-4' />
                            </button>
                        </div>
                } */}

        {/* foot */}
        <div className='fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800'>
          {/* <CustomNavigation activeIndex={2} /> */}
        </div>
      </div>
    </Fragment>
  )
}

export default BranchesScreen
