import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'
import { UserIcon } from '@heroicons/react/24/solid'
import { User } from '../types'

type NavBannerProps = {
  //   showExtra: boolean
  //   notificationEvents: {}[]
  //   children: React.ReactNode
  isLoading: boolean
  userPersonal: User | null | undefined
}

const NavBanner = ({ isLoading, userPersonal }: NavBannerProps) => {
  const navigate = useNavigate()
  return (
    <div className='flex items-center w-full dark:bg-dark-100 dark:text-white'>
      {/* <button onClick={() => navigate('/notifications')}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
            >
              <path
                d='M17.7935 28.6299C16.8035 28.6299 15.8435 28.2299 15.1435 27.5299C14.4435 26.8299 14.0435 25.8699 14.0435 24.8799H15.5435C15.5435 25.4699 15.7835 26.0499 16.2035 26.4699C16.6235 26.8899 17.2035 27.1299 17.7935 27.1299C19.0335 27.1299 20.0435 26.1199 20.0435 24.8799H21.5435C21.5435 26.9499 19.8635 28.6299 17.7935 28.6299Z'
                fill='#939291'
              />
              <path
                d='M19.6533 9.76C19.5833 9.76 19.5133 9.75 19.4433 9.73C19.1533 9.65 18.8733 9.59 18.6033 9.55C17.7533 9.44 16.9333 9.5 16.1633 9.73C15.8833 9.82 15.5833 9.73 15.3933 9.52C15.2033 9.31 15.1433 9.01 15.2533 8.74C15.6633 7.69 16.6633 7 17.8033 7C18.9433 7 19.9433 7.68 20.3533 8.74C20.4533 9.01 20.4033 9.31 20.2133 9.52C20.0633 9.68 19.8533 9.76 19.6533 9.76Z'
                fill='#939291'
              />
              <path
                d='M17.7933 26.3502C15.4633 26.3502 13.1333 25.9802 10.9233 25.2402C10.0833 24.9502 9.44331 24.3602 9.16331 23.5902C8.87331 22.8202 8.97331 21.9702 9.43331 21.2102L10.5833 19.3002C10.8233 18.9002 11.0433 18.1002 11.0433 17.6302V14.7402C11.0433 11.0202 14.0733 7.99023 17.7933 7.99023C21.5133 7.99023 24.5433 11.0202 24.5433 14.7402V17.6302C24.5433 18.0902 24.7633 18.9002 25.0033 19.3102L26.1433 21.2102C26.5733 21.9302 26.6533 22.8002 26.3633 23.5902C26.0733 24.3802 25.4433 24.9802 24.6533 25.2402C22.4533 25.9802 20.1233 26.3502 17.7933 26.3502ZM17.7933 9.49023C14.9033 9.49023 12.5433 11.8402 12.5433 14.7402V17.6302C12.5433 18.3602 12.2433 19.4402 11.8733 20.0702L10.7233 21.9802C10.5033 22.3502 10.4433 22.7402 10.5733 23.0702C10.6933 23.4102 10.9933 23.6702 11.4033 23.8102C15.5833 25.2102 20.0133 25.2102 24.1933 23.8102C24.5533 23.6902 24.8333 23.4202 24.9633 23.0602C25.0933 22.7002 25.0633 22.3102 24.8633 21.9802L23.7133 20.0702C23.3333 19.4202 23.0433 18.3502 23.0433 17.6202V14.7402C23.0433 11.8402 20.6933 9.49023 17.7933 9.49023Z'
                fill='#939291'
              />
              {notificationEvents.length > 0 && (
                <path
                  d='M26.75 11C26.75 12.5188 25.5188 13.75 24 13.75C22.4812 13.75 21.25 12.5188 21.25 11C21.25 9.48122 22.4812 8.25 24 8.25C25.5188 8.25 26.75 9.48122 26.75 11Z'
                  fill='#F15B50'
                  stroke='white'
                  stroke-width='0.5'
                />
              )}
            </svg>
          </button> */}

      {isLoading ? (
        <div className='px-5 py-7 w-full bg-[#F5F5F5] flex items-center gap-x-3 dark:bg-dark-100 dark:text-white'>
          <Skeleton
            active={isLoading}
            paragraph={{ rows: 1 }}
            avatar={{ shape: 'square' }}
            style={{ height: 27 }}
            title={false}
          />
        </div>
      ) : (
        <div className='px-5 py-3 w-full bg-[#F5F5F5] flex items-center gap-x-3 dark:bg-dark-100 dark:text-white'>
          {userPersonal?.image ? (
            <button className='w-10 h-10' onClick={() => navigate('/personal')}>
              <img
                className='rounded-md w-full h-full object-cover'
                src={userPersonal?.image}
                alt='profile'
              />
            </button>
          ) : (
            <button onClick={() => navigate('/personal')}>
              <UserIcon className='w-8 h-8' />
            </button>
          )}

          <div
            className='text-[#484442] font-medium'
            onClick={() => navigate('/personal')}
          >
            <h2 className='text-base'>
              {userPersonal?.first_name} {userPersonal?.last_name}
            </h2>
            <h2 className='text-[10px] cursor-pointer'>View profile</h2>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavBanner
