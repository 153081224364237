import { IoMdClose } from 'react-icons/io'

type Props = {
  onClose: () => void
}

export default function CallToAction({ onClose }: Props) {
  return (
    <div className='bg-gradient-to-r from-[#FF0800] to-[#FFB080] rounded-xl text-white p-3'>
      <div className='flex justify-between items-center w-full font-bold mb-3'>
        <p>Have bits of available coupons?</p>
        <IoMdClose onClick={onClose} className='cursor-pointer' />
      </div>

      <div className='flex justify-between items-center w-full gap-x-3'>
        <p className='text-sm'>
          Merge bits of available coupons into a single coupon for ease of use
          at the fuel station
        </p>
        <img src='/merge.png' alt='merge' className='w-[30px] h-[30px]' />
      </div>
    </div>
  )
}
